import {
  Backdrop,
  Box,
  Button,
  Grid,
  Link,
  MuiThemeProvider,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  accountHealthDemoData,
  dispatchStatusDemoData,
  overdueOrdersSummaryDemoData,
  salesMixDemoData,
  salesTrendDemoData,
} from "~/modules/widgets/partner/demoData";
import { get, isEmpty } from "lodash";
import styled, { ThemeProvider } from "styled-components";

import AccountHealthSummaryContents from "~/modules/widgets/overview/accountHealthSummaryContents";
import Carousel from "react-material-ui-carousel";
import CircleOutlinedIcon from "@material-ui/icons/LensOutlined";
import DispatchStatusContents from "~/modules/widgets/overview/dispatchStatusContents";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import NoData from "~/components/loadingIndicator/noData";
import OverdueOrderSummaryContents from "~/modules/widgets/overview/overdueOrderSummaryContents";
import { OverviewOverdueOrders } from "@typedef/store";
import PageBlock from "~/components/containers/pageBlock";
import Panel from "~/components/panel/panel";
import { RouteComponentProps } from "react-router-dom";
import SalesMixPieChartContents from "~/modules/widgets/overview/salesMixPieChartContents";
import StepChart from "~/components/charts/stepChart/stepChart";
import cookies from "browser-cookies";
import { fetchAccountHealth } from "~/store/overview/accountHealth.redux";
import { fetchAverageSalesByDay } from "~/store/mystore/averageSalesByDay.redux";
import { fetchDispatchStatus } from "~/store/overview/dispatchStatus.redux";
import { fetchOverdueOrderSummary } from "~/store/overview/overdueOrders.redux";
import { fetchSalesByDay } from "~/store/mystore/salesByDay.redux";
import { fetchSalesMix } from "~/store/overview/salesMix.redux";
import { getExpectedAndActualSales } from "~/utils/salesUtils";
import { hasOnlyDemoStores } from "~/utils/marketplaceUtils";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";
import moment from "moment-timezone";
import theme from "~/theming/omnivoreTheme";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;
const DEFAULT_CURRENCY = "AUD";
const DEFAULT_PERIOD = DATETIME_PERIODS.LAST30;
const DEFAULT_RANGE = getDatesFromPeriod(
  DEFAULT_PERIOD,
  COMPARISON_PERIOD.THISYEAR,
  moment.tz.guess()
);
const DEFAULT_FILTER = {
  marketplaces: [],
  countries: [],
  tags: [],
};

const StyledLink = styled(Link)`
  width: 100%;
  text-align: right;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const RelativeBox = styled(Box)`
  position: relative;
`;

const SquareBox = styled(Box)`
  aspect-ratio: 1;
`;

const StyledCircleOutlinedIcon = styled(CircleOutlinedIcon)`
  width: 8px;
  height: 8px;
`;

const LimitedBackdrop = styled(Backdrop)`
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ActivateText = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.white};
`;

const ActionButton = styled(Button)`
  margin: 16px;
  padding: 16px;
  width: 280px;
  border-radius: 0;
  text-transform: uppercase;
  &,
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const StepChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex-grow: 1;
`;

export const SquareWidgetPage = memo<RouteComponentProps<{ partner: string }>>(
  function SquareWidgetPage(props) {
    const { t } = useTranslation();
    const queryParams = useQueryParams();
    const dispatch = useDispatch();

    const source = queryParams.get("source");
    const token = queryParams.get("token");
    const partnerName = props.match.params.partner || "unknown";

    const THEME_OVERRIDES: Record<string, Theme> = {
      omnivore: theme,
      default: useCustomTheme(),
    };
    const themeToUse =
      THEME_OVERRIDES[partnerName] || THEME_OVERRIDES["default"];

    const user = useTypedSelector((state) => state.user);
    const userCheck = user && user._id;

    const filteredStores = useTypedSelector(
      (state) => state?.mystore?.filteredStores?.stores
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );

    const [showingDemoData, setShowingDemoData] = useState(true);

    const salesMix = useTypedSelector((state) =>
      get(state, "overview.salesMix")
    );
    const isSalesMixFetching = get(salesMix, "fetching", true);

    const dispatchStatus = useTypedSelector((state) =>
      get(state, "overview.dispatchStatus")
    );
    const isDispatchStatusFetching = get(dispatchStatus, "fetching", true);

    const salesByDay = useTypedSelector((state) =>
      get(state, "overview.salesByDay")
    );
    const isSalesByDayFetching = get(salesByDay, "fetching", true);

    const averageSalesByDay = useTypedSelector((state) =>
      get(state, "overview.averageSalesByDay")
    );
    const isAverageSalesByDayFetching = get(
      averageSalesByDay,
      "fetching",
      true
    );

    const accountHealth = useTypedSelector((state) =>
      get(state, "overview.accountHealth")
    );
    const isAccountHealthFetching = get(accountHealth, "fetching", true);

    const overdueOrdersSummary = useTypedSelector((state) =>
      get(state, "overview.overdueOrders")
    );
    const isOverdueOrdersSummaryFetching = get(
      overdueOrdersSummary,
      "fetching",
      true
    );

    const dispatchFetchData = useCallback(() => {
      dispatch(
        fetchSalesMix({
          user,
          currentPeriod: DEFAULT_PERIOD,
          currentRange: DEFAULT_RANGE,
          filter: DEFAULT_FILTER,
          filteredStores,
          includeTax: true,
        })
      );
      dispatch(
        fetchDispatchStatus({
          user,
          filter: DEFAULT_FILTER,
          filteredStores,
          timezone: selectedTimezone,
        })
      );
      dispatch(
        fetchSalesByDay({
          mid: undefined,
          market: undefined,
          filter: DEFAULT_FILTER,
          filteredStores,
          includeTax: true,
          timezone: selectedTimezone,
        })
      );
      dispatch(
        fetchAverageSalesByDay({
          timezone: moment.tz.guess(),
          mid: undefined,
          market: undefined,
          filter: DEFAULT_FILTER,
          filteredStores,
          includeTax: true,
        })
      );
      dispatch(
        fetchAccountHealth({
          user: { _id: user?._id, organisationId: user?.organisationId },
          pageNum: 0,
          pageSize: PAGE_SIZE,
          filter: DEFAULT_FILTER,
          filteredStores,
          timezone: selectedTimezone,
        })
      );
      dispatch(
        fetchOverdueOrderSummary({
          user: { _id: user?._id, organisationId: user?.organisationId },
          pageIndex: 0,
          pageSize: PAGE_SIZE,
          filter: DEFAULT_FILTER,
          filteredStores,
          timezone: selectedTimezone,
        })
      );
    }, [userCheck, filteredStores]);

    useEffect(() => {
      if (userCheck) {
        const isShowingDemoData = hasOnlyDemoStores(filteredStores);
        setShowingDemoData(isShowingDemoData);
      } else {
        setShowingDemoData(true);
      }

      if (window.Intercom) {
        window.Intercom("update", {
          hide_default_launcher: true,
        });
      }
    }, [userCheck, filteredStores]);

    // This is available after a user logs in to our application -
    // this is separate from the "token" value earlier, which is
    // basically a one-use token that the user can exchange for this one.
    const loggedInToken = cookies.get("accessToken");
    useEffect(() => {
      if (loggedInToken && userCheck) {
        jwtInterceptor(null, location.pathname);
      }
    }, [userCheck, loggedInToken, location.pathname]);

    useEffect(() => {
      const fetchData = async () => {
        dispatchFetchData();
      };

      if (!showingDemoData) {
        fetchData();
      }
    }, [showingDemoData]);

    let salesTrend = salesTrendDemoData;

    if (
      !showingDemoData &&
      !isSalesByDayFetching &&
      !isAverageSalesByDayFetching &&
      salesByDay.data &&
      averageSalesByDay.data
    ) {
      salesTrend = getExpectedAndActualSales(
        salesByDay.data,
        averageSalesByDay.data
      );
    }

    return (
      <MuiThemeProvider theme={themeToUse}>
        <ThemeProvider theme={themeToUse}>
          <RelativeBox>
            {showingDemoData && (
              <LimitedBackdrop open>
                <Grid container>
                  <Grid item xs={12}>
                    <ActivateText align="center">
                      {t("partnerWidget.activateText", {
                        partnerName: t(`partnerWidget.${partnerName}`),
                      })}
                    </ActivateText>
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <Link
                      href={`/api/partner/${partnerName}/auth?source=${source}&token=${token}`}
                      target="_blank"
                      component={ActionButton}
                    >
                      {t("partnerWidget.activateButton")}
                    </Link>
                  </Grid>
                </Grid>
              </LimitedBackdrop>
            )}
            <Carousel
              animation="slide"
              autoPlay={false}
              stopAutoPlayOnHover={true}
              navButtonsAlwaysInvisible={true}
              IndicatorIcon={<StyledCircleOutlinedIcon fontSize="small" />}
              indicatorIconButtonProps={{
                style: {
                  color: theme.palette.primary.light,
                  marginRight: "2px",
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  backgroundColor: theme.palette.primary.light,
                  height: "6px",
                  width: "6px",
                  marginLeft: "1px",
                  marginRight: "3px",
                },
              }}
              indicatorContainerProps={{
                style: {
                  marginTop: "-43px",
                  marginLeft: "14px",
                  textAlign: "left",
                  paddingBottom: "14px",
                },
              }}
            >
              <PageBlock disableGutters>
                <SquareBox>
                  <Panel
                    id="widget-partner-sales-mix"
                    title={t("partnerWidget.salesMix.mainTitle")}
                    elevation={0}
                    compact
                    content={
                      !showingDemoData && isSalesMixFetching ? (
                        <Box p={2}>
                          <LoadingIndicator />
                        </Box>
                      ) : (
                        <SalesMixPieChartContents
                          compact
                          salesMix={
                            showingDemoData ? salesMixDemoData : salesMix
                          }
                        />
                      )
                    }
                    footer={
                      <StyledLink
                        href="/overview"
                        target="_blank"
                        variant="subtitle2"
                      >
                        <Typography>
                          {t("partnerWidget.salesMix.linkTitle")}
                        </Typography>
                      </StyledLink>
                    }
                  />
                </SquareBox>
              </PageBlock>
              <PageBlock disableGutters>
                <SquareBox>
                  <Panel
                    id="widget-partner-dispatch-status"
                    title={t("partnerWidget.dispatchStatus.mainTitle")}
                    elevation={0}
                    compact
                    content={
                      !showingDemoData && isDispatchStatusFetching ? (
                        <Box p={2}>
                          <LoadingIndicator />
                        </Box>
                      ) : (
                        <DispatchStatusContents
                          compact
                          dispatchStatus={
                            showingDemoData
                              ? dispatchStatusDemoData
                              : dispatchStatus
                          }
                        />
                      )
                    }
                    footer={
                      <StyledLink
                        href="/overview/orderStatus?type=overdueOrders"
                        target="_blank"
                        variant="subtitle2"
                      >
                        <Typography>
                          {t("partnerWidget.dispatchStatus.linkTitle")}
                        </Typography>
                      </StyledLink>
                    }
                  />
                </SquareBox>
              </PageBlock>
              <PageBlock disableGutters>
                <SquareBox>
                  <Panel
                    id="widget-partner-sales-trend"
                    title={t("partnerWidget.salesTrend.mainTitle")}
                    elevation={0}
                    compact
                    content={
                      !showingDemoData &&
                      (isSalesByDayFetching || isAverageSalesByDayFetching) ? (
                        <Box p={2}>
                          <LoadingIndicator />
                        </Box>
                      ) : isEmpty(salesTrend) ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          <NoData />
                        </Box>
                      ) : (
                        <StepChartWrapper>
                          <StepChart
                            {...{
                              data: salesTrend,
                              currentCurrency: DEFAULT_CURRENCY,
                              compact: true,
                              timezone: selectedTimezone,
                            }}
                          />
                        </StepChartWrapper>
                      )
                    }
                    footer={
                      <StyledLink
                        href="/overview"
                        target="_blank"
                        variant="subtitle2"
                      >
                        <Typography>
                          {t("partnerWidget.salesTrend.linkTitle")}
                        </Typography>
                      </StyledLink>
                    }
                  />
                </SquareBox>
              </PageBlock>
              <PageBlock disableGutters>
                <SquareBox>
                  <Panel
                    id="widget-partner-account-health"
                    title={t("partnerWidget.accountHealth.mainTitle")}
                    elevation={0}
                    compact
                    content={
                      !showingDemoData && isAccountHealthFetching ? (
                        <Box p={2}>
                          <LoadingIndicator />
                        </Box>
                      ) : (
                        <AccountHealthSummaryContents
                          compact
                          accountHealth={
                            showingDemoData
                              ? accountHealthDemoData
                              : accountHealth
                          }
                          fetchAccountHealth={() => () => {}} // no-op
                        />
                      )
                    }
                    footer={
                      <StyledLink
                        href="/overview"
                        target="_blank"
                        variant="subtitle2"
                      >
                        <Typography>
                          {t("partnerWidget.accountHealth.linkTitle")}
                        </Typography>
                      </StyledLink>
                    }
                  />
                </SquareBox>
              </PageBlock>
              <PageBlock disableGutters>
                <SquareBox>
                  <Panel
                    id="widget-partner-overdue-orders"
                    title={t("partnerWidget.overdueOrders.mainTitle")}
                    elevation={0}
                    compact
                    content={
                      !showingDemoData && isOverdueOrdersSummaryFetching ? (
                        <Box p={2}>
                          <LoadingIndicator />
                        </Box>
                      ) : (
                        <OverdueOrderSummaryContents
                          compact
                          overdueOrderSummary={
                            showingDemoData
                              ? (overdueOrdersSummaryDemoData as unknown as OverviewOverdueOrders)
                              : overdueOrdersSummary
                          }
                          fetchOverdueOrderSummary={() => () => {}} // no-op
                        />
                      )
                    }
                    footer={
                      <StyledLink
                        href="/overview"
                        target="_blank"
                        variant="subtitle2"
                      >
                        <Typography>
                          {t("partnerWidget.overdueOrders.linkTitle")}
                        </Typography>
                      </StyledLink>
                    }
                  />
                </SquareBox>
              </PageBlock>
            </Carousel>
          </RelativeBox>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
);

export default SquareWidgetPage;
