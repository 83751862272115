import { Box, Grid, useTheme } from "@material-ui/core";
import { CurrencyRate, Range, RetailAnalyticsTrend } from "~/typedef/store";
import React, { memo, useMemo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import LineAndBarChart from "~/components/charts/comboChart/lineAndBarChart";
import { User } from "~/typedef/user";
import { useRetailAnalyticsChartQuery } from "~/store/mystore/vendor.redux";
import { getConvertedValue } from "~/utils/currencyUtils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface RetailAnalyticsByProductTableChartParams {
  userInfo: User;
  mid: string;
  productId: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
}

const formatRetailAnalyticByProductData = (
  retailAnalyticByProductTrend: RetailAnalyticsTrend[] | undefined,
  currentCurrency: string,
  currency = "AUD",
  currencyRates: CurrencyRate[]
) => {
  if (!retailAnalyticByProductTrend) {
    return [];
  }
  return retailAnalyticByProductTrend.map((data: RetailAnalyticsTrend) => {
    return {
      startTime: data.startTime,
      endTime: data.endTime,
      ordered: Number.isFinite(data.retailPrice)
        ? getConvertedValue(
            currencyRates,
            currency,
            currentCurrency,
            data.ordered
          )
        : data.ordered,
      retailPrice: Number.isFinite(data.retailPrice)
        ? getConvertedValue(
            currencyRates,
            currency,
            currentCurrency,
            data.retailPrice
          )
        : data.retailPrice,
    };
  });
};

const RetailAnalyticsByProductTableChart = memo(
  ({
    userInfo,
    mid,
    productId,
    currentPeriod,
    currentRange,
    currentCurrency,
    timezone,
  }: RetailAnalyticsByProductTableChartParams) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const currency = useTypedSelector(
      (state) => state?.vendor?.retailAnalyticsByProduct?.chart.currency
    );

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const {
      retailAnalyticChartByProductTrend,
      retailAnalyticsByProductFetching,
    } = useRetailAnalyticsChartQuery(
      {
        user: userInfo,
        currentRange,
        mid,
        productId,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          retailAnalyticChartByProductTrend:
            data?.retailAnalyticChartData || [],
          retailAnalyticsByProductFetching: isFetching,
        }),
      }
    );

    const data = useMemo(() => {
      return formatRetailAnalyticByProductData(
        retailAnalyticChartByProductTrend,
        currentCurrency,
        currency,
        currencyRates
      );
    }, [
      currentRange,
      mid,
      productId,
      userInfo,
      retailAnalyticChartByProductTrend,
      currentCurrency,
    ]);

    return (
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <LineAndBarChart
              title={t(CHART_TITLES[currentPeriod])}
              currentPeriod={currentPeriod}
              currentCurrency={currentCurrency}
              chartData={data}
              isLoading={retailAnalyticsByProductFetching}
              line={{
                key: "retailPrice",
                toFixed: 1,
                colour: theme.palette.secondary.main,
                type: "step",
              }}
              bar={{
                key: "ordered",
                colour: theme.palette.primary.main,
                toFixed: 0,
              }}
              xKey="startTime"
              xKey2="endTime"
              report={true}
              timezone={timezone}
              interval={currentRange?.interval}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default RetailAnalyticsByProductTableChart;
