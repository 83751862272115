import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import { Filter, Store } from "~/typedef/store";
import { Grid, MenuItem } from "@material-ui/core";
import { MAX_STORES, ReportConfigForm } from "./reportConfigForm";
import React, { memo, useEffect, useState } from "react";
import {
  fetchClientReportConfig,
  fetchClientReportConfigById,
  setInitialState,
  useGetReportTemplateByIdQuery,
} from "~/store/mystore/clientReport.redux";
import { isEmpty, isEqual } from "lodash";

import EditReportRecipients from "./editReportRecipients";
import { Option } from "~/components/dragAndDrop/dragAndDrop";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ReportPreview from "./reportPreview";
import { ScheduledReportType } from "~/modules/widgets/scheduledReportsTable";
import get from "lodash/get";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useQueryParams from "~/hooks/useQueryParams";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

export interface FrequencyOption {
  value: string | number;
  text: string;
}

export enum ReportType {
  SINGLE = "SINGLE",
  MULTI = "MULTI",
}

export const FREQUENCY_OPTIONS: FrequencyOption[] = [
  { value: 1, text: "createReport.sendOnce" },
  { value: "d", text: "createReport.daily" },
  { value: "w", text: "createReport.weekly" },
  { value: "m", text: "createReport.monthly" },
];

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem;
  max-width: 800px;
`;

interface MenuItemProps {
  width: number | null;
}

export const FrequencyMenuItem = styled(MenuItem)`
  width: ${({ width }: MenuItemProps) => width}px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

interface ClientReportProps {
  isEdit?: boolean;
  editReport?: ScheduledReportType;
  onClose?: () => void;
}

const CreateReport = memo<ClientReportProps>(function CreateReport({
  isEdit,
  editReport,
  onClose,
}) {
  const user = useTypedSelector((state) => get(state, "user"));
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCurrency = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const includeTaxDefault = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const preferredTimezone = useTypedSelector(
    (state) => state?.persistentAppSettings?.setting?.data?.preferredTimezone
  );

  const reportId = get(editReport, "reportId");
  const clientReport = useTypedSelector((state) => get(state, "clientReport"));
  const dispatch = useDispatch();

  const [reportType, setReportType] = useState(
    get(clientReport, "reportConfig.reportType") || ReportType.SINGLE
  );

  const [stores, setStores] = useState<Store[]>(
    get(clientReport, "stores") || []
  );

  const [selectedTags, setSelectedTags] = useState(
    get(clientReport, "reportConfig.selectedTags") || []
  );

  const [name, setName] = useState(
    get(clientReport, "reportConfig.name") || ""
  );

  const [pageSize, setPageSize] = useState(
    get(clientReport, "reportConfig.pageSize") || PAGE_SIZE
  );
  const [includeTax, setIncludeTax] = useState(
    get(clientReport, "reportConfig.includeTax") ?? includeTaxDefault
  );
  const [conditionalFormatting, setConditionalFormatting] = useState(
    get(clientReport, "reportConfig.conditionalFormatting")
  );
  const [period, setPeriod] = useState(
    get(clientReport, "reportConfig.period") || currentPeriod
  );
  const [comparisonPeriod, setComparisonPeriod] = useState(
    get(clientReport, "reportConfig.comparisonPeriod") || currentCompare
  );
  const [fromDate, setFromDate] = useState(
    get(clientReport, "reportConfig.fromDate") || currentRange.fromDate
  ); // default to current range
  const [toDate, setToDate] = useState(
    get(clientReport, "reportConfig.toDate") || currentRange.toDate
  );
  const [priorFromDate, setPriorFromDate] = useState(
    get(clientReport, "reportConfig.priorFromDate") ||
      currentRange.priorFromDate
  ); // default to current range
  const [priorToDate, setPriorToDate] = useState(
    get(clientReport, "reportConfig.priorToDate") || currentRange.priorToDate
  );
  const [interval, setInterval] = useState(
    get(clientReport, "reportConfig.interval") || currentRange.interval
  );
  const [currency, setCurrency] = useState(
    get(clientReport, "reportConfig.currency") || currentCurrency
  );
  const [emailToBeSent, setEmailToBeSent] = useState(
    get(clientReport, "reportConfig.emailRecipient") || [user.email]
  );

  const [timezone, setTimezone] = useState(
    isEdit
      ? get(editReport, "timezone")
      : get(clientReport, "reportConfig.timezone") || selectedTimezone
  );
  const [frequency, setFrequency] = useState<string | number>(1); // once off
  const [reportingDelay, setReportingDelay] = useState<number | undefined>(
    undefined
  ); // in days
  const [widgets, setWidgets] = useState<Option[]>(
    get(clientReport, "reportConfig.widgets") || []
  );
  const [storeFilter, setStoreFilter] = useState<Filter | undefined>();

  const [showPreview, setShowPreview] = useState(!isEdit);

  const queryParams = useQueryParams();
  const templateId = queryParams.get("template");

  const { isReportTemplateLoading, template } = useGetReportTemplateByIdQuery(
    {
      templateId,
    },
    {
      skip: !templateId,
      selectFromResult: ({ data, isLoading }) => ({
        template: data?.template,
        isReportTemplateLoading: isLoading,
      }),
    }
  );

  useEffect(() => {
    if (
      get(clientReport, "reportConfig.name") !== name ||
      get(clientReport, "reportConfig.period") !== period ||
      get(clientReport, "reportConfig.comparisonPeriod") !== comparisonPeriod ||
      get(clientReport, "reportConfig.pageSize") !== pageSize ||
      get(clientReport, "reportConfig.fromDate") !== fromDate ||
      get(clientReport, "reportConfig.toDate") !== toDate ||
      get(clientReport, "reportConfig.frequency") !== frequency ||
      get(clientReport, "reportConfig.reportingDelay") !== reportingDelay ||
      !isEqual(get(clientReport, "reportConfig.widgets"), widgets) ||
      isEmpty(stores)
    ) {
      setShowPreview(false);
    } else {
      setShowPreview(!isEdit);
    }
  }, [
    clientReport,
    name,
    period,
    comparisonPeriod,
    pageSize,
    fromDate,
    toDate,
    frequency,
    reportingDelay,
    widgets,
  ]);

  useEffect(() => {
    if (!isEdit && !templateId) {
      setStores([]);
      setSelectedTags([]);
      setName("");
      setPeriod(currentPeriod);
      setComparisonPeriod(currentCompare);
      setPageSize(PAGE_SIZE);
      setFromDate(currentRange.fromDate); // default to current range
      setToDate(currentRange.toDate);
      setPriorFromDate(currentRange.priorFromDate);
      setPriorToDate(currentRange.priorToDate);
      setInterval(currentRange.interval);
      setTimezone(selectedTimezone);
      setCurrency(currentCurrency);
      setIncludeTax(includeTaxDefault);
      setFrequency(1); // once off
      setReportingDelay(undefined);
      setWidgets([]);
    }
  }, [reportType]);

  useEffect(() => {
    if (
      !templateId &&
      !clientReport.fetching &&
      !clientReport.sending &&
      !clientReport.deleting
    ) {
      if (
        get(clientReport, "reportConfig") &&
        (reportType === get(clientReport, "reportConfig.reportType") || isEdit)
      ) {
        if (
          !isEqual(get(clientReport, "reportConfig.reportType"), reportType)
        ) {
          setReportType(
            get(clientReport, "reportConfig.reportType") || ReportType.SINGLE
          );
        }

        if (isEdit && !isEqual(get(clientReport, "stores"), stores)) {
          setStores(get(clientReport, "stores") || []);
        }

        if (
          !isEqual(get(clientReport, "reportConfig.selectedTags"), selectedTags)
        ) {
          setSelectedTags(get(clientReport, "reportConfig.selectedTags") || []);
        }
        setName(get(clientReport, "reportConfig.name") || "");
        setPeriod(get(clientReport, "reportConfig.period") || currentPeriod);
        setComparisonPeriod(
          get(clientReport, "reportConfig.comparisonPeriod") || currentCompare
        );
        setPageSize(get(clientReport, "reportConfig.pageSize") || PAGE_SIZE);
        setFromDate(
          get(clientReport, "reportConfig.fromDate") || currentRange.fromDate
        );
        setToDate(
          get(clientReport, "reportConfig.toDate") || currentRange.toDate
        );
        setPriorFromDate(
          get(clientReport, "reportConfig.priorFromDate") ||
            currentRange.priorFromDate
        );
        setPriorToDate(
          get(clientReport, "reportConfig.priorToDate") ||
            currentRange.priorToDate
        );
        setInterval(
          get(clientReport, "reportConfig.interval") || currentRange.interval
        );
        setTimezone(
          get(clientReport, "reportConfig.timezone") || selectedTimezone
        );
        setCurrency(
          get(clientReport, "reportConfig.currency") || currentCurrency
        );
        setIncludeTax(
          get(clientReport, "reportConfig.includeTax") ?? includeTaxDefault
        );
        setConditionalFormatting(
          get(clientReport, "reportConfig.conditionalFormatting")
        );
        setFrequency(get(clientReport, "reportConfig.frequency") || 1);
        setReportingDelay(get(clientReport, "reportConfig.reportingDelay"));
        const widgets = get(clientReport, "reportConfig.widgets");
        setWidgets(widgets || []);
      } else {
        setName("");
        setPeriod(currentPeriod);
        setComparisonPeriod(currentCompare);
        setPageSize(PAGE_SIZE);
        setFromDate(currentRange.fromDate); // default to current range
        setToDate(currentRange.toDate);
        setPriorFromDate(currentRange.priorFromDate);
        setPriorToDate(currentRange.priorToDate);
        setInterval(currentRange.interval);
        setTimezone(selectedTimezone);
        setCurrency(currentCurrency);
        setIncludeTax(includeTaxDefault);
        setFrequency(1); // once off
        setReportingDelay(undefined);
        setWidgets([]);
      }
    }
  }, [clientReport.reportConfig]);

  useEffect(() => {
    if (templateId && template) {
      const { stores, reportConfig } = template;
      setStores(stores);
      setStoreFilter({
        marketplaces: [stores[0]?.marketplaceType],
        tags: [],
        countries: [],
      });
      if (reportConfig) {
        setName(reportConfig.name);
        setReportType(reportConfig.reportType as ReportType);
        setSelectedTags(reportConfig.selectedTags || []);
        setPeriod(reportConfig.period);
        setComparisonPeriod(reportConfig.comparisonPeriod as COMPARISON_PERIOD);
        reportConfig.fromDate && setFromDate(reportConfig.fromDate);
        reportConfig.toDate && setToDate(reportConfig.toDate);
        reportConfig.priorFromDate &&
          setPriorFromDate(reportConfig.priorFromDate);
        reportConfig.priorToDate && setPriorToDate(reportConfig.priorToDate);
        reportConfig.timezone && setTimezone(reportConfig.timezone);
        reportConfig.interval && setInterval(reportConfig.interval);
        setFrequency(reportConfig.frequency);
        setPageSize(reportConfig.pageSize);
        setReportingDelay(reportConfig.reportingDelay);
        setCurrency(reportConfig.currency);
        setIncludeTax(reportConfig.includeTax);
        setConditionalFormatting(reportConfig.conditionalFormatting);
        setWidgets([...reportConfig.widgets]);
      }
    }
  }, [template]);

  useEffect(() => {
    dispatch(setInitialState());
  }, []);

  useEffect(() => {
    if (!isEdit && stores.length && !(stores.length > MAX_STORES)) {
      dispatch(
        fetchClientReportConfig({
          userId: user?._id,
          reportType,
          stores,
          selectedTags,
        })
      );
    }
  }, [user?._id, reportType, stores, clientReport.success]);

  useEffect(() => {
    if (isEdit) {
      dispatch(
        fetchClientReportConfigById({
          reportId: editReport?.reportId || "",
        })
      );
    }
  }, []);

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <ReportConfigForm
          {...{
            reportType,
            setReportType,
            setFrequency,
            setReportingDelay,
            setShowPreview,
            setFromDate,
            setToDate,
            setPriorFromDate,
            setPriorToDate,
            setInterval,
            setName,
            setPeriod,
            setComparisonPeriod,
            setPageSize,
            setIncludeTax,
            setConditionalFormatting,
            timezone,
            setTimezone,
            preferredTimezone,
            setCurrency,
            currency,
            includeTax,
            conditionalFormatting,
            period,
            name,
            comparisonPeriod,
            pageSize,
            fromDate,
            toDate,
            priorFromDate,
            priorToDate,
            interval,
            frequency,
            reportingDelay,
            widgets,
            setWidgets,
            stores,
            setStores,
            selectedTags,
            setSelectedTags,
            reportToEdit: editReport,
            reportId,
            isEdit,
            onClose,
            templateName: template?.reportConfig?.name,
            templateUserId: template?.userId,
            isReportTemplateLoading,
            storeFilter,
            setStoreFilter,
          }}
        />
        {clientReport.reportConfig && showPreview && (
          <ReportPreview
            frequency={frequency}
            reportingDelay={reportingDelay}
            name={name}
            fromDate={fromDate}
            toDate={toDate}
            period={period}
            stores={stores}
            reportType={reportType}
            selectedTags={selectedTags}
            emailToBeSent={emailToBeSent}
            setEmailToBeSent={setEmailToBeSent}
          />
        )}
        {isEdit && editReport?.reportId && (
          <EditReportRecipients
            reportId={editReport.reportId}
            initEmailToBeSent={editReport.reportRecipients}
          />
        )}
      </Grid>
    </PageBlock>
  );
});

export default CreateReport;
