import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  COMMENTARY_FILTERS,
  useFetchNotesQuery,
} from "~/store/mystore/notes.redux";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  DATETIME_PERIODS_KEY,
  INTERVAL,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import DragAndDrop, { Option } from "~/components/dragAndDrop/dragAndDrop";
import {
  FREQUENCY_OPTIONS,
  Form,
  FrequencyMenuItem,
  FrequencyOption,
} from "./createReport";
import { Filter, Store } from "~/typedef/store";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  editScheduledReport,
  saveClientReportConfig,
  useSaveReportConfigTempateMutation,
} from "~/store/mystore/clientReport.redux";
import { get, isEmpty, isEqual, omit } from "lodash";
import {
  getExtraConfiguration,
  getMultiStoreOptions,
  getReportDayAndTime,
  getScheduledDescription,
  getSingleStoreOptions,
} from "./reportUtils";

import AdvancedConfiguration from "./advancedConfiguration";
import { Alert } from "@material-ui/lab";
import ComparisonPeriodSelect from "~/components/select/comparisonPeriodSelect";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import { DateRange } from "~/typedef/date";
import DateRangeDropdown from "~/components/dateRangePicker/fullDateTimeDropdown";
import { ErrorMessage } from "~/pages/marketplaceConnectors/amazonUpdateStoreNamePage";
import PageSizeDropdown from "~/components/select/pageSizeDropdown";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import { RawDraftContentState } from "draft-js";
import { ReportType } from "~/pages/reports/createReport";
import { ScheduledReportType } from "~/modules/widgets/scheduledReportsTable";
import { SelectStoreByTag } from "~/components/select/selectStoreByTag";
import StatusText from "~/components/typography/status";
import { StoreSelector } from "~/components/toolbars/myStoresToolbar/storeSelector";
import ToolbarSelect from "~/components/select/toolbarSelect";
import cookies from "browser-cookies";
import { getFriendlyLongUserName } from "~/store/utils/displayUtils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const MAX_STORES = 100;
const MAX_STORES_WARNING = 50;

export const ClientReportFormButton = styled(RaisedButton)`
  ${({ theme }) => `
      ${theme.breakpoints.only("xs")} {
        min-width: 120px;
      }
      ${theme.breakpoints.up("sm")} {
        min-width: 150px;
      }
    `}
`;

export const ClientReportSaveTemplateButton = styled(RaisedButton)`
  ${({ theme }) => `
  margin-left:8px;
      ${theme.breakpoints.only("xs")} {
        min-width: 120px;
      }
      ${theme.breakpoints.up("sm")} {
        min-width: 150px;
      }
    `}
`;

export const ErrorMessageWithPadding = styled(Typography)`
  min-height: 1.5em;
  padding-left: 12px;
  padding-top: 4px;
`;

export type ProcessedWidgetOption = Option & {
  currentPeriod?: DATETIME_PERIODS;
  comparisonPeriod?: COMPARISON_PERIOD;
  currentRange?: DateRange;
};

interface ReportConfigFormProps {
  setReportType: (type: ReportType) => void;
  setFrequency: (freq: string | number) => void;
  setReportingDelay: (reportingDelay: number | undefined) => void;
  setShowPreview: (showPreview: boolean) => void;
  setFromDate: (fromDate: number) => void;
  setToDate: (toDate: number) => void;
  setPriorFromDate: (fromDate: number) => void;
  setPriorToDate: (toDate: number) => void;
  setInterval: (interval: INTERVAL) => void;
  setName: (name: string) => void;
  setPeriod: (period: string) => void;
  setComparisonPeriod: (period: COMPARISON_PERIOD) => void;
  setPageSize: (pageSize: number) => void;
  setStores: (stores: Store[]) => void;
  setSelectedTags: (selectedTags: string[]) => void;
  reportType: ReportType;
  setIncludeTax: (includeTax: boolean) => void;
  setConditionalFormatting: (conditionalFormatting: boolean) => void;
  includeTax: boolean;
  conditionalFormatting: boolean;
  setTimezone: (timezone: string) => void;
  setCurrency: (curr: string) => void;
  currency: string;
  timezone: string;
  preferredTimezone?: string;
  period: DATETIME_PERIODS;
  name: string;
  comparisonPeriod: COMPARISON_PERIOD;
  pageSize: number;
  fromDate: number;
  toDate: number;
  priorFromDate: number;
  priorToDate: number;
  interval: INTERVAL;
  frequency: string | number;
  reportingDelay?: number;
  widgets: Option[];
  setWidgets: (widgets: Option[]) => void;
  stores: Store[];
  selectedTags?: string[];
  isEdit?: boolean;
  reportId?: string;
  reportToEdit?: ScheduledReportType;
  onClose?: () => void;
  templateName: string | undefined;
  templateUserId: string | undefined;
  isReportTemplateLoading: boolean;
  setStoreFilter: (filter: Filter | undefined) => void;
  storeFilter: Filter | undefined;
}
export const ReportConfigForm: React.FC<ReportConfigFormProps> = ({
  setReportType,
  setFrequency,
  setReportingDelay,
  setShowPreview,
  setFromDate,
  setToDate,
  setPriorFromDate,
  setPriorToDate,
  setInterval,
  setName,
  setPeriod,
  setComparisonPeriod,
  setPageSize,
  setStores,
  setSelectedTags,
  reportType,
  setIncludeTax,
  setConditionalFormatting,
  conditionalFormatting,
  setTimezone,
  setCurrency,
  currency,
  timezone,
  preferredTimezone,
  includeTax,
  period,
  name,
  comparisonPeriod,
  pageSize,
  fromDate,
  toDate,
  priorFromDate,
  priorToDate,
  interval,
  frequency,
  reportingDelay,
  widgets,
  setWidgets,
  stores,
  selectedTags,
  reportToEdit,
  isEdit,
  reportId,
  onClose,
  templateName,
  templateUserId,
  isReportTemplateLoading,
  storeFilter,
  setStoreFilter,
}) => {
  const user = useTypedSelector((state) => get(state, "user"));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const clientReport = useTypedSelector((state) => get(state, "clientReport"));
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );

  const defaultDateOptions = Object.keys(
    omit(DATETIME_PERIODS, "DAY")
  ) as DATETIME_PERIODS_KEY[];
  const scheduledDateOptions = Object.keys(
    omit(DATETIME_PERIODS, "DAY", "CUSTOM")
  ) as DATETIME_PERIODS_KEY[];
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = useState(
    anchorEl ? anchorEl.offsetWidth : null
  );

  const [dateOptions, setDateOptions] = useState(defaultDateOptions);
  const [extraConfiguration, setExtraConfiguration] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const singleStoreOptions = getSingleStoreOptions(
    t,
    stores[0]?.marketplaceType as Marketplace,
    stores[0]?.marketplaceSubtype as Marketplace,
    stores[0]?.marketplaceCountry,
    user
  );

  const multiStoreOptions = getMultiStoreOptions(t, stores, selectedTags || []);

  const getOptions = () =>
    reportType === ReportType.MULTI ? multiStoreOptions : singleStoreOptions;

  const handleFrequencySelect = (option: FrequencyOption) => {
    const newFreq = option.value;
    setFrequency(newFreq);
    if (newFreq === 1) {
      // Send Once - no delay
      setReportingDelay(undefined);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (frequency === 1) {
      setDateOptions(defaultDateOptions);
    } else {
      setDateOptions(scheduledDateOptions);
    }
  }, [frequency]);

  useEffect(() => {
    if (frequency !== 1) {
      const {
        fromDate: convertedFromDate,
        toDate: convertedToDate,
        interval: dateInterval,
        priorFromDate: convertedPriorFromDate,
        priorToDate: convertedPriorToDate,
      } = getDatesFromPeriod(
        period,
        comparisonPeriod,
        timezone,
        undefined,
        undefined,
        undefined,
        undefined,
        interval
      );
      setFromDate(convertedFromDate);
      setToDate(convertedToDate);
      setPriorFromDate(convertedPriorFromDate);
      setPriorToDate(convertedPriorToDate);
      setInterval(dateInterval);
    }
  }, [timezone]);

  useEffect(() => {
    if (
      // If the user switches away from a monthly, quarterly etc interval,
      // restore a friendlier timezone than UTC
      timezone === "GMT" &&
      preferredTimezone
    ) {
      setTimezone(preferredTimezone);
    }
  }, [interval]);

  useEffect(() => {
    const value = widgets.some(
      (widget) =>
        (widget.selectedConfiguration || widget.extraConfiguration) &&
        widget.dropped
    );

    setExtraConfiguration(value);
  }, [widgets]);

  useEffect(() => {
    const value = widgets
      .filter((widget) => widget.extraConfiguration)
      .every(
        (widget) =>
          widget.selectedConfiguration &&
          !isEmpty(widget.selectedConfiguration) &&
          widget.dropped
      );

    setSelectedConfiguration(value);
  }, [widgets]);

  const FrequencyOption = useCallback(
    ({ option }: { option: FrequencyOption }) => (
      <FrequencyMenuItem
        key={option.text}
        width={menuWidth}
        onClick={() => handleFrequencySelect(option)}
      >
        <Typography variant="body2">{t(option.text)}</Typography>
      </FrequencyMenuItem>
    ),
    [menuWidth]
  );

  const renderOptions = useCallback(
    () =>
      FREQUENCY_OPTIONS.map((option) => {
        return <FrequencyOption key={option.value} option={option} />;
      }),
    []
  );

  const changeReportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (setReportType) {
      setReportType((event.target as HTMLInputElement).value as ReportType);
    }
  };

  const changeStore = (_market: string, mid: string): void => {
    const store = filteredStores?.stores?.find(
      (store) => store.merchantId === mid
    );
    if (setStores && store) {
      setStores([store]);
    }
  };

  const { data: notes, isFetching: isLoadingNotes } = useFetchNotesQuery(
    {
      ...(reportType === ReportType.SINGLE
        ? {
            mid: stores[0]?.merchantId,
            marketplaceType: stores[0]?.marketplaceType,
            marketplaceSubtype: stores[0]?.marketplaceSubtype,
            filters: [
              COMMENTARY_FILTERS.OVERVIEW,
              COMMENTARY_FILTERS.CUSTOMER,
              COMMENTARY_FILTERS.SALES,
              COMMENTARY_FILTERS.MARKETING,
              COMMENTARY_FILTERS.OTHER,
              COMMENTARY_FILTERS.OPERATIONS,
              COMMENTARY_FILTERS.PROFITABILITY,
            ],
          }
        : {
            tags: selectedTags || [],
          }),
    },
    {
      skip:
        (reportType === ReportType.MULTI && !selectedTags?.length) ||
        (reportType === ReportType.SINGLE && !stores.length),
    }
  );

  const getPeriod = (): DATETIME_PERIODS => {
    return (Object.values(DATETIME_PERIODS).find(
      (value) => value === period
    ) || [""]) as DATETIME_PERIODS;
  };

  const processWidgetsForReportConfig = useCallback<
    (widgets: Option[]) => ProcessedWidgetOption[]
  >(
    (widgets) => {
      return widgets.map((widget) => {
        if (widget.customRange) {
          switch (widget.customRange) {
            case DATETIME_PERIODS.YEAR:
              return {
                ...widget,
                currentPeriod: DATETIME_PERIODS.YEAR,
                comparisonPeriod: COMPARISON_PERIOD.LASTYEAR,
                currentRange: getDatesFromPeriod(
                  DATETIME_PERIODS.YEAR,
                  COMPARISON_PERIOD.LASTYEAR,
                  timezone,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  widget.customInterval ?? interval
                ),
              };
            case DATETIME_PERIODS.CURRENTYEAR:
              return {
                ...widget,
                currentPeriod: DATETIME_PERIODS.CURRENTYEAR,
                comparisonPeriod: COMPARISON_PERIOD.LASTYEAR,
                currentRange: getDatesFromPeriod(
                  DATETIME_PERIODS.CURRENTYEAR,
                  COMPARISON_PERIOD.LASTYEAR,
                  timezone,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  widget.customInterval ?? interval
                ),
              };
          }
        }
        return widget;
      });
    },
    // comparisonPeriod will be used when we add other custom ranges
    // YTD is the only one for now
    [comparisonPeriod, timezone, interval]
  );

  const getReportConfigNotes = () => {
    if (notes && notes.notes?.length > 0) {
      return {
        notes: notes.notes.reduce((acc, note) => {
          if ("filter" in note) {
            acc[note.filter] = note.note;
          }
          if ("tag" in note) {
            acc[note.tag] = note.note;
          }
          return acc;
        }, {} as Record<string | COMMENTARY_FILTERS, RawDraftContentState>),
      };
    }

    return {};
  };

  const handleSave = () => {
    dispatch(
      saveClientReportConfig({
        userId: user?._id,
        preparedBy: getFriendlyLongUserName(user) || "",
        stores,
        originalDomain: cookies.get("originalDomain"),
        agency: user.agency,
        authSource: cookies.get("authSource"),
        reportConfig: {
          reportType: reportType || ReportType.SINGLE,
          name,
          period,
          comparisonPeriod,
          pageSize,
          fromDate,
          toDate,
          priorFromDate,
          priorToDate,
          interval,
          frequency,
          reportingDelay,
          selectedTags,
          widgets: processWidgetsForReportConfig(widgets),
          timezone,
          currency,
          includeTax,
          conditionalFormatting,
          ...getReportConfigNotes(),
        },
        t,
      })
    );
    setShowPreview(!isEdit);
  };

  const [
    saveTemplate,
    { handleSaveTemplateLoading, handleSaveTemplateStatus },
  ] = useSaveReportConfigTempateMutation({
    selectFromResult: ({ data, isLoading }) => ({
      handleSaveTemplateLoading: isLoading,
      handleSaveTemplateStatus: data?.status,
    }),
  });

  useEffect(() => {
    if (handleSaveTemplateStatus?.includes("existingTemplate")) {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  }, [handleSaveTemplateStatus]);

  const handleSaveTemplate = (forceSaveValue?: boolean) => {
    saveTemplate({
      successMsg: t("clientReport.reportTemplateSavedSuccessMessage"),
      stores,
      forceSave: forceSaveValue || false,
      reportConfig: {
        reportType: reportType || ReportType.SINGLE,
        name,
        period,
        comparisonPeriod,
        ...(period === DATETIME_PERIODS.CUSTOM
          ? {
              fromDate,
              toDate,
              priorFromDate,
              priorToDate,
              interval,
              timezone,
            }
          : { timezone, interval }),
        ...getReportConfigNotes(),
        pageSize,
        frequency,
        reportingDelay,
        selectedTags,
        widgets: processWidgetsForReportConfig(widgets),
        currency,
        includeTax,
        conditionalFormatting,
      },
    });
  };

  const handleEdit = () => {
    if (reportToEdit) {
      const { reportDay } = getReportDayAndTime(
        frequency,
        timezone,
        reportingDelay
      );
      dispatch(
        editScheduledReport({
          t,
          reportId,
          stores,
          editedReportConfig: {
            reportType: reportType || ReportType.SINGLE,
            name,
            period,
            comparisonPeriod,
            pageSize,
            fromDate,
            toDate,
            interval,
            priorFromDate,
            priorToDate,
            frequency,
            reportingDelay,
            selectedTags,
            widgets: processWidgetsForReportConfig(widgets),
            timezone,
            currency,
            includeTax,
            conditionalFormatting,
            ...getReportConfigNotes(),
          },
          editedScheduledReport: {
            reportRecipients: reportToEdit.reportRecipients,
            frequency,
            reportingDelay,
            stores,
            timezone,
            period,
            reportDay,
            reportName: name,
            reportUrl: reportToEdit.reportUrl,
            type: "scheduled",
            reportId,
            reportType,
            selectedTags: selectedTags || [],
          },
        })
      );
      setShowPreview(!isEdit);
      if (onClose) {
        onClose();
      }
    }
  };

  const onSetComparisonPeriod = useCallback(
    (comparison: COMPARISON_PERIOD) => {
      const range = getDatesFromPeriod(period, comparison, timezone);
      setDates(range, period, comparison, timezone);
    },
    [period, timezone]
  );

  const setDates = (
    range: DateRange,
    period: DATETIME_PERIODS,
    comparison: COMPARISON_PERIOD,
    timezone: string
  ) => {
    setFromDate(range.fromDate);
    setToDate(range.toDate);
    setPriorFromDate(range.priorFromDate);
    setPriorToDate(range.priorToDate);
    setInterval(range.interval);
    setPeriod(period);
    setTimezone(timezone);
    setComparisonPeriod(comparison);
  };

  const setSelected = (id: string, values: any) => {
    const selectedWidget = widgets.find((widget) => widget.id === id);
    if (selectedWidget) {
      setWidgets([
        ...widgets.filter((widget) => widget.id !== id),
        {
          ...selectedWidget,
          selectedConfiguration: values,
          extraConfiguration: getExtraConfiguration(
            selectedWidget,
            singleStoreOptions
          ),
        },
      ]);
    }
  };

  const getExtraConfigError = (id: string) => {
    switch (id) {
      case "advertisingPerformanceTrend":
      case "shippedRevenueChart":
      case "shippedRevenueChartYTD":
      case "shippedRevenueChartCurrentYear":
      case "shippedRevenueLineChartCurrentYear":
        return "createReport.noMetricSelectedError";
      case "repeatPurchaseTrend":
      case "customerLTVTrend":
        return "createReport.noChartTypeSelectedError";
      default:
        return "createReport.noGroupsSelectedError";
    }
  };

  const renderExtraConfiguration = () => {
    return widgets.map((widget) => {
      if (widget.dropped) {
        const Component = widget.extraConfiguration
          ? (widget.extraConfiguration as unknown as FC<{
              setSelected: (values: any) => void;
              selected: any;
              store: Store;
            }>)
          : widget.selectedConfiguration
          ? getExtraConfiguration(widget, singleStoreOptions)
          : null;

        return Component ? (
          <Grid key={widget.id} item xs={12}>
            <FormControl fullWidth>
              <Box pl={2} width={200}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingBottom: "8px", paddingTop: "8px" }}
                >
                  {widget.text}
                </Typography>
                <Component
                  setSelected={(values: any) => setSelected(widget.id, values)}
                  selected={widget.selectedConfiguration}
                  store={stores[0]}
                />
                {isEmpty(widget?.selectedConfiguration) && (
                  <ErrorMessageWithPadding color="error">
                    {t(getExtraConfigError(widget.id))}
                  </ErrorMessageWithPadding>
                )}
              </Box>
            </FormControl>
          </Grid>
        ) : null;
      }
    });
  };

  const clearStoreFilter = () => {
    setStoreFilter(undefined);
    setWidgets([]);
    history.push("/reports/createReport");
  };

  return (
    <Grid item xs={12}>
      {isEdit &&
      (!isEqual(get(clientReport, "reportConfig.selectedTags"), selectedTags) ||
        clientReport.fetching ||
        isReportTemplateLoading ||
        isLoadingNotes) ? (
        <PanelLoading />
      ) : (
        <>
          <Panel
            id="create-report"
            title={`${t("panel.createReport")}${
              storeFilter
                ? t("panel.reportTemplateTitle", {
                    name: templateName,
                  })
                : ""
            }`}
            content={
              <Form
                method="POST"
                onSubmit={isEdit ? () => handleEdit() : () => handleSave()}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl disabled={!!templateName}>
                      <FormLabel focused={false}>
                        {t("createReport.reportType")}
                      </FormLabel>
                      <RadioGroup
                        row
                        value={reportType}
                        onChange={changeReportType}
                      >
                        <FormControlLabel
                          value={ReportType.SINGLE}
                          control={<Radio color="primary" size="small" />}
                          label={t("createReport.singleStore")}
                        />
                        <FormControlLabel
                          value={ReportType.MULTI}
                          control={<Radio color="primary" size="small" />}
                          label={t("createReport.multiStore")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {reportType === ReportType.SINGLE && (
                    <Grid item xs={12} md={6}>
                      <StoreSelector
                        changeStore={changeStore}
                        currentStore={{
                          marketplace: stores[0]?.marketplaceSubtype,
                          merchantId: stores[0]?.merchantId,
                          sourceSystemId: stores[0]?.merchantId.split(" @")[0],
                          marketplaceCountry: stores[0]?.marketplaceCountry,
                          storeName: stores[0]?.storeName,
                        }}
                        currentFilter={templateName ? storeFilter : undefined}
                        clearFilters={clearStoreFilter}
                      />
                    </Grid>
                  )}
                  {reportType === ReportType.MULTI &&
                    setStores &&
                    setSelectedTags &&
                    selectedTags && (
                      <Grid container item xs={12} md={12}>
                        <Grid item xs={12} md={6}>
                          <SelectStoreByTag
                            existingTags={get(
                              clientReport,
                              "reportConfig.selectedTags"
                            )}
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                            setStores={setStores}
                          />
                          {filteredStores.fetching ? null : (
                            <>
                              {stores?.length > MAX_STORES_WARNING &&
                                stores?.length <= MAX_STORES && (
                                  <ErrorMessage color="error">
                                    {t("createReport.numberOfStoresWarning")}
                                  </ErrorMessage>
                                )}
                              {stores?.length > MAX_STORES ? (
                                <ErrorMessage color="error">
                                  {t("createReport.numberOfStoresError")}
                                </ErrorMessage>
                              ) : null}
                              {selectedTags?.length && !stores?.length ? (
                                <ErrorMessage color="error">
                                  {t("createReport.noStoresError")}
                                </ErrorMessage>
                              ) : null}
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} alignItems="center">
                          <Box pt={4} />
                          {filteredStores.fetching ? (
                            <CircularProgress size={20} />
                          ) : (
                            <StatusText status="info">
                              {t("createReport.numberOfStores", {
                                stores: stores.length,
                              })}
                            </StatusText>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  {clientReport.fetching ? (
                    <Grid item xs={12}>
                      <PanelLoading />
                    </Grid>
                  ) : (
                    !isEmpty(stores) && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              label={t("createReport.formHeader")}
                              placeholder={t(
                                "createReport.formHeaderPlaceholder"
                              )}
                              required
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl fullWidth>
                            <ToolbarSelect
                              {...{
                                id: "report-frequency-select",
                                title: t("createReport.selectFrequencyTitle"),
                                renderOptions,
                                anchorEl,
                                setAnchorEl,
                                menuWidth,
                                setMenuWidth,
                                rightAlign: false,
                                displayComponent: (
                                  <FrequencyOption
                                    option={
                                      FREQUENCY_OPTIONS.find(
                                        (option) => option.value === frequency
                                      ) || FREQUENCY_OPTIONS[0]
                                    }
                                  />
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Box display="flex" alignItems="flex-end">
                            <FormControl>
                              <DateRangeDropdown
                                title={t("createReport.selectDateRangeTitle")}
                                setDates={setDates}
                                period={getPeriod()}
                                range={{
                                  fromDate,
                                  toDate,
                                  priorFromDate,
                                  priorToDate,
                                  interval,
                                  timezone,
                                }}
                                compare={comparisonPeriod}
                                timezone={timezone}
                                preferredTimezone={preferredTimezone}
                                options={dateOptions}
                                rightAlign={true}
                                useList={frequency !== 1}
                                maxWidth={frequency == 1 ? undefined : 300}
                              />
                            </FormControl>
                            {frequency !== 1 && (
                              <FormControl>
                                <Box pl={2}>
                                  <ComparisonPeriodSelect
                                    setCompare={onSetComparisonPeriod}
                                    currentCompare={comparisonPeriod}
                                  />
                                </Box>
                              </FormControl>
                            )}
                            <FormControl>
                              <Box pl={2}>
                                <PageSizeDropdown
                                  setPageSize={setPageSize}
                                  pageSize={pageSize}
                                />
                              </Box>
                            </FormControl>
                          </Box>
                        </Grid>
                        <AdvancedConfiguration
                          includeTax={includeTax}
                          conditionalFormatting={conditionalFormatting}
                          setIncludeTax={setIncludeTax}
                          setConditionalFormatting={setConditionalFormatting}
                          setTimezone={setTimezone}
                          setInterval={setInterval}
                          setCurrency={setCurrency}
                          setReportingDelay={setReportingDelay}
                          currency={currency}
                          fromDate={fromDate}
                          toDate={toDate}
                          period={period}
                          timezone={timezone}
                          interval={interval}
                          frequency={frequency}
                          reportingDelay={reportingDelay}
                        />
                        <Grid item xs={12}>
                          <StatusText variant="body2" status="info" noWrap>
                            {getScheduledDescription(
                              frequency,
                              t,
                              timezone,
                              reportingDelay
                            )}
                          </StatusText>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            {t("createReport.elementsToInclude")}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {t("createReport.elementsToIncludeDescription")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <DragAndDrop
                              allOptions={getOptions()}
                              selectedOptions={widgets
                                .sort((a, b) => a.position - b.position)
                                .map((w, i) => ({ ...w, position: i }))}
                              setSelectedOptions={setWidgets}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )
                  )}
                </Grid>
              </Form>
            }
            secondaryContentTitle={
              extraConfiguration ? (
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ paddingBottom: "8px" }}>
                    {t("createReport.widgetConfiguration")}
                  </Typography>
                </Grid>
              ) : undefined
            }
            secondaryContent={
              <>
                {extraConfiguration ? (
                  <>{renderExtraConfiguration()}</>
                ) : undefined}
              </>
            }
            footer={
              <Box display="space-evenly" width="100%">
                <ClientReportFormButton
                  disabled={
                    !name ||
                    clientReport.sending ||
                    clientReport.fetching ||
                    !widgets?.length ||
                    stores?.length > MAX_STORES ||
                    (extraConfiguration && !selectedConfiguration)
                  }
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={isEdit ? handleEdit : handleSave}
                >
                  {clientReport.sending ? (
                    <CircularProgress size={20} />
                  ) : (
                    t("createReport.generateButton")
                  )}
                </ClientReportFormButton>
                {(user?._id === templateUserId || !templateUserId) && (
                  <ClientReportSaveTemplateButton
                    disabled={
                      stores.length <= 0 ||
                      handleSaveTemplateLoading ||
                      !name ||
                      clientReport.sending ||
                      clientReport.fetching ||
                      !widgets?.length ||
                      stores?.length > MAX_STORES ||
                      (extraConfiguration && !selectedConfiguration)
                    }
                    type="submit"
                    variant="contained"
                    color="info"
                    onClick={() => handleSaveTemplate()}
                  >
                    {clientReport.sending || handleSaveTemplateLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      t("createReport.saveTemplate")
                    )}
                  </ClientReportSaveTemplateButton>
                )}
              </Box>
            }
          />
          <ConfirmDialog
            open={openDialog}
            title={t("generic.confirmTitle")}
            onClose={() => {
              setOpenDialog(false);
            }}
            onConfirm={() => {
              setOpenDialog(false);
              handleSaveTemplate(true);
            }}
            content={
              <>
                <Typography variant="body1">
                  {t("createReport.confirmEditTemplateMessage")}
                </Typography>
              </>
            }
          />
        </>
      )}
    </Grid>
  );
};
