import React, { memo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import RetailAnalyticsByProductTable from "../widgets/retailAnalyticsByProductTable";
import SearchFilter from "~/components/adTable/searchFilter";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface RetailAnalyticsByProductProps {
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  sellerId: string;
  countryCode: string;
  isSourcingView: boolean;
  condensed?: boolean;
}

const RetailAnalyticsByProduct = memo<RetailAnalyticsByProductProps>(
  function RetailAnalyticsByProduct({
    mid,
    marketplace,
    marketplaceSubtype,
    condensed,
    sellerId,
    countryCode,
    isSourcingView,
  }) {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);
    const store = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const urlSearchParams = useQueryParams();
    const { currentRange, currentPeriod, selectedTimezone } =
      useDateRangeFilters();
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const [searchText, setSearchText] = useState("");

    const groupId = urlSearchParams.get("groupId");

    const footerLink =
      condensed &&
      (!marketplace.includes("amazon_vendor") || userInfo.brandAnalyticsOn)
        ? {
            url: marketplaceLink(marketplace, mid, ""),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    if (store) {
      const exchangeRate = getExchangeRate(
        currencyRates,
        getCurrencyByCountryCode[store.marketplaceCountry],
        currentCurrency
      );

      return (
        <RetailAnalyticsByProductTable
          mid={mid}
          marketplace={marketplace}
          marketplaceSubtype={marketplaceSubtype}
          condensed={condensed}
          sellerId={sellerId}
          countryCode={countryCode}
          currentPeriod={currentPeriod}
          currentRange={currentRange}
          currentCurrency={currentCurrency}
          footerLink={footerLink}
          searchText={searchText}
          includeNoInventory={true}
          includeTax={includeTax}
          groupId={groupId}
          timezone={selectedTimezone}
          isSourcingView={isSourcingView}
          actions={
            <>
              {!condensed && (
                <>
                  <SearchFilter {...{ setSearchText }} />
                  <DownloadCsv
                    {...{
                      //TO_DO create new service for retail analytics CSV download
                      reportType: "retailAnalyticsByProduct",
                      path: "/api/bff/retailAnalyticsByProduct",
                      mid: store.merchantId,
                      params: {
                        shopName: store.storeName,
                        mid,
                        marketplace,
                        marketplaceSubtype,
                        sellerId,
                        countryCode,
                        ...currentRange,
                        sortOrder: "asc",
                        searchText,
                        includeNoInventory: true,
                        includeTax,
                        ...(Boolean(groupId) && { groupId }),
                        targetCurrency: currentCurrency,
                        exchangeRate,
                        label: "",
                      },
                    }}
                  />
                </>
              )}
            </>
          }
        />
      );
    }
    return <LoadingIndicator />;
  }
);

export default RetailAnalyticsByProduct;
